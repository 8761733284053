import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    FormDataConsumer,
    SelectInput,
    required,
    email,
    regex,
} from 'react-admin';
import {UserRoles} from '../common.js'

const UsersCreate = (props) => {

    return (
        <Create {...props} redirect={"show"}  >
            <SimpleForm >
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="surname" validate={[required()]}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="password" type="password" validate={[required(), regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$€%^&*(),.?":{}|<>_-])(?=.{12,})/, 'La contraseña debe tener mínimo 12 caracteres y debe incluir una combinación de mayúsculas, minúsculas, números y caracteres especiales.')]} />
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            <>
                                <div className="child-items">
                                    <SelectInput source="admin_user.role" validate={[required()]} choices={UserRoles} />
                                </div>
                            </>
                        )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}

export default UsersCreate;