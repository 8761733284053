import {sendWhatsApp} from "../../../../api";

export const WhatsAppSenderTestCallback = async (callback) => {
    if (typeof callback !== 'function') {
        console.error('WhatsAppSenderTestCallback: La llamada debe suministrar una función');
        alert('La llamada debe suministrar una función');
        return;
    }

    const data = callback();
    const {
        whatsappSenderId,
        wabaKey,
        whatsappTemplate,
        whatsappTo,
        lang,
        clientId,
    } = data;

    try {
        return await sendWhatsApp({
            senderId: whatsappSenderId,
            wabaToken: wabaKey,
            template: whatsappTemplate,
            to: whatsappTo,
            lang: lang,
            clientId: clientId
        });
    } catch (error) {
        console.error("Error al enviar WhatsApp:", error);
        throw error;
    }
};
