import React, {useState} from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    useNotify,
    useRedirect,
    ImageInput,
    ImageField,
    BooleanInput,
    FormDataConsumer,
    SelectInput,
    required,
    email,
    regex,
} from 'react-admin';
import {TechnicalUserRoles, UserAuthorizedSections, UserRoles} from '../common.js'
import {SelectArrayInput} from "ra-ui-materialui/dist/cjs/input/SelectArrayInput";

const UsersCreate = (props) => {
    const [technicalSelectionRole, setTechnicalSelectionRole] = useState(null);

    const notify = useNotify();

    const onError = (error) => {
        if(error.body.errors) {
            const errors = (typeof error.body.errors === 'string') ? [error.body.errors] : error.body.errors;
            errors.forEach(error => (
              notify(`ra.users.${error}`, { type: 'error' })
            ));
        } else {
            notify("ra.notification.http_error", { type: 'error' });
        }
    };

    return (
        <Create {...props} redirect={"show"}  mutationOptions={{ onError }}>
            <SimpleForm >
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="surname" validate={[required()]}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="password" type="password" validate={[required(), regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$€%^&*(),.?":{}|<>_-])(?=.{12,})/, 'La contraseña debe tener mínimo 12 caracteres y debe incluir una combinación de mayúsculas, minúsculas, números y caracteres especiales.')]}/>
                <ReferenceInput
                    source="client.id"
                    reference="clients"
                    sort={{field: 'name', order: 'ASC'}}
                    perPage={1000}
                >
                    <AutocompleteInput
                        source="name"
                        optionText="name"
                        filterToQuery={(searchText) => ({ name: searchText})}
                    />
                </ReferenceInput>


                <ImageInput source="imageBase64" accept="image/*">
                    <ImageField source="src" title="title" multiple={false} />
                </ImageInput>


                <BooleanInput source="technical_selection_user.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData, ...rest }) => (
                            formData.technical_selection_user &&
                            formData.technical_selection_user.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="technical_selection_user.role" validate={[required()]} choices={TechnicalUserRoles} onChange={(item) => {
                                        if (item && item.target && item.target.value) {
                                            setTechnicalSelectionRole(item.target.value);
                                        }
                                    }}/>
                                </div>
                                {technicalSelectionRole && technicalSelectionRole === 'BASIC' && <div className="child-items">
                                    <SelectArrayInput source="user_authorized_sections" validate={[required()]}
                                                      choices={UserAuthorizedSections}
                                    />
                                </div>}
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="technical_selection_user.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>

                <BooleanInput source="rrhh_user.enabled"/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        formData.rrhh_user &&
                        formData.rrhh_user.enabled &&
                        <>
                            <div className="child-items">
                                <SelectInput source="rrhh_user.role" validate={[required()]} choices={TechnicalUserRoles} />
                            </div>
                            <div className="child-items">
                                <TextInput fullWidth={true} multiline source="rrhh_user.filters_default" label="filtersUserDefault"/>
                            </div>
                        </>
                    )}
                </FormDataConsumer>

                <BooleanInput source="user_on_boarding.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData, ...rest }) => (
                            formData.user_on_boarding &&
                            formData.user_on_boarding.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_on_boarding.role" validate={[required()]} choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_on_boarding.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
                <BooleanInput source="user_feedback.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.user_feedback &&
                            formData.user_feedback.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_feedback.role" validate={[required()]} choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_feedback.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
                <BooleanInput source="user_exit_interview.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.user_exit_interview &&
                            formData.user_exit_interview.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_exit_interview.role" validate={[required()]} choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_exit_interview.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}

export default UsersCreate;
