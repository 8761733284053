import React, { useState } from 'react';
import Dialog from "../../molecules/dialog";
import {useRecordContext} from "react-admin";

const ClickableIdField = ({source, style}) => {
    const record = useRecordContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
              <span
                  style={{ cursor: 'pointer', ...style }}
                  onClick={handleOpenModal}
              >
                {record.id}
            </span>
            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                {record && (
                    <div style={{"display": "flex", "width": "100%"}}>
                        <div style={{"display": "flex"}}>
                            <pre>{record.variables ? JSON.stringify(record.variables, undefined, 2) : "{}"}</pre>
                        </div>
                        <div style={{"display": "flex"}}>
                            <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://cell.hrbotfactory.com/?s="+record.id+"&cooler=true' width='400' height='600' style='border: solid 1px darkgrey;border-radius: 8px;' />"}} />
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default ClickableIdField;
