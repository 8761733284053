import React, {useState} from "react";
import {
    BooleanField,
    EmailField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    Error,
    Loading,
    DateField,
    Button,
} from 'react-admin';
import { useParams } from "react-router-dom";
import { useGetOne } from "react-admin";
import { MODULE_IDS } from "../../../../config/api";
import {ColorField} from "react-admin-color-picker";
import LogoField from "../../../atoms/logo-field/index";
import Dialog from "../../../molecules/dialog";
import {Snackbar, Typography} from "@mui/material";
import {renewClient} from "../../../../api";
import i18n from "../../../assets/i18n";

const ClientsShow = (props) => {
    const { id } = useParams();
    const { data, loading, error } = useGetOne('clients', { id });
    const [openRenovationModal, setOpenRenovationModal] = useState(false);
    const [renovationSnackbar, setRenovationSnackbar] = useState("");

    if (loading) return <Loading />;
    if (error) return <Error />;

    const renewClientAction = () => {
        renewClient(id).then(r => {
            if (r.status === 200) {
                setOpenRenovationModal(false);
                setRenovationSnackbar(i18n.es.contract.renovation_success);
                setTimeout(() => {
                    setRenovationSnackbar("");
                }, 3000);
            } else {
                setRenovationSnackbar(i18n.es.contract.renovation_error);
                setTimeout(() => {
                    setRenovationSnackbar("");
                }, 3000);
            }
        })
    }

    return (
        <Show >
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id" />
                    <TextField source="name" />
                    <EmailField source="email" />
                    <TextField source="email_from" />
                    <BooleanField source="sms_available" />
                    <BooleanField source="whatsapp_available" />
                    <TextField source="sms_prefix" />
                    <TextField source="sendgrid_token" />
                    <TextField source="whatsapp_voucher" />
                    <TextField source="data_retention_months" />
                    <TextField source="legal_name" />
                    <TextField source="nif" />
                    <TextField source="address" />
                    <TextField source="billing_address" />
                    <TextField source="city" />
                    <TextField source="cp" />
                    <TextField source="province" />
                    <TextField source="country" />
                    <BooleanField source="bot_starter_notify_always" />
                    <BooleanField source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
                </Tab>

                <Tab  resource="clients" label="resources.clients.tabs.colors">
                    <ColorField source="text_color_principal" />
                    <ColorField source="text_color_secondary" />
                    <ColorField source="background_color" />
                    <LogoField record={data} source="logo" />
                </Tab>

                <Tab  resource="clients" label={i18n.es.contract.title}>
                    <BooleanField source="is_velora" label={i18n.es.contract.is_velora} />
                    <BooleanField source="is_free_trial" label={i18n.es.contract.is_free_trial} />
                    <DateField source="init_trial_at" label={i18n.es.contract.init_trial_at}/>
                    <DateField source="expire_at" label={i18n.es.contract.expire_at}/>
                    <BooleanField source="is_velora_assesment" label={i18n.es.contract.is_velora_assesment} />
                    <TextField source="max_users" />
                    <TextField source="max_jobflows" label={i18n.es.contract.max_jobflows}/>
                    <TextField source="max_persons" label={i18n.es.contract.max_persons}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default ClientsShow
